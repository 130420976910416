import React, { useState } from "react";
import "./Dashboard.css";
import {
  ArrowLeftSquare,
  Clipboard,
  Person,
  Shop,
} from "react-bootstrap-icons";

const Dashboard = () => {
  const [activePage, setActivePage] = React.useState(0);
  const [openedOrder, setOpenedOrder] = React.useState(null);
  const [openedCustomer, setOpenedCustomer] = React.useState(null);
  const [token, setToken] = React.useState(null);

  useState(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      // window.location = "/login";
    } else {
      setToken(token);
    }
  });

  return (
    <div className="Dashboard">
      <div className="Dashboard_title">
        DASHBOARD
        <br />
        <span className="Dashboard_title_japanese">ダッシュボード</span>
      </div>
      <div className="Dashboard_content">
        {activePage === 0 && openedOrder === null && (
          <div className="Dashboard_content_page">
            <div className="Dashboard_content_section">
              <div className="Dashboard_content_title">Dernières commandes</div>
              <div
                className="Dashboard_content_section_order"
                onClick={() => setOpenedOrder(99999)}
              >
                <div className="Dashboard_content_section_order_number">
                  99999
                </div>
                <div className="Dashboard_content_section_order_date">
                  01/01/2021
                </div>
                <div className="Dashboard_content_section_order_price">
                  9999 €
                </div>
                <div className="Dashboard_content_section_order_status">
                  En cours
                </div>
              </div>
              <div
                className="Dashboard_content_section_order"
                onClick={() => setOpenedOrder(99999)}
              >
                <div className="Dashboard_content_section_order_number">
                  99999
                </div>
                <div className="Dashboard_content_section_order_date">
                  01/01/2021
                </div>
                <div className="Dashboard_content_section_order_price">
                  9999 €
                </div>
                <div className="Dashboard_content_section_order_status">
                  En cours
                </div>
              </div>
              <div
                className="Dashboard_content_section_order"
                onClick={() => setOpenedOrder(99999)}
              >
                <div className="Dashboard_content_section_order_number">
                  99999
                </div>
                <div className="Dashboard_content_section_order_date">
                  01/01/2021
                </div>
                <div className="Dashboard_content_section_order_price">
                  9999 €
                </div>
                <div className="Dashboard_content_section_order_status">
                  En cours
                </div>
              </div>
            </div>
          </div>
        )}
        {activePage === 1 && openedCustomer === null && (
          <div className="Dashboard_content_page">
            <div className="Dashboard_content_section">
              <div className="Dashboard_content_title">Derniers clients</div>
              <div
                className="Dashboard_content_section_order"
                onClick={() => setOpenedCustomer(99999)}
              >
                <div className="Dashboard_content_section_order_number">
                  99999
                </div>
                <div className="Dashboard_content_section_order_nom">
                  Dupont Jean
                </div>
                <div className="Dashboard_content_section_order_last_order_date">
                  01/01/2021
                </div>
              </div>
            </div>
          </div>
        )}
        {activePage === 2 && (
          <div className="Dashboard_content_page">
            <div className="Dashboard_content_section">
              <div className="Dashboard_content_title">Magasins</div>
              <div className="Dashboard_content_section_order">
                <div className="Dashboard_content_section_order_number">1</div>
                <div className="Dashboard_content_section_order_nom">
                  U Porto-Vecchio
                </div>
                <div className="Dashboard_content_section_order_last_order_date">
                  Livré le: 01/01/2021
                </div>
                <div className="Dashboard_content_section_order_status">
                  <div className="Dashboard_content_section_order_pastille">
                    0
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activePage === 0 && openedOrder !== null && (
          <div className="Dashboard_content_page">
            <div className="Dashboard_content_articles_list">
              <div className="Dashboard_content_title">
                Commande n° {openedOrder}
              </div>
              <div className="Dashboard_content_article">
                <div className="Dashboard_content_article_name">
                  Sushi Saumon
                </div>
                <div className="Dashboard_content_article_quantity">2</div>
                <div className="Dashboard_content_article_check">
                  <input type="checkbox" />
                </div>
              </div>
            </div>
          </div>
        )}
        {activePage === 1 && openedCustomer !== null && (
          <div className="Dashboard_content_page">
            <div className="Dashboard_content_articles_list">
              <div className="Dashboard_content_title">
                Client n° {openedCustomer}
              </div>
              <div
                className="Dashboard_content_article"
                onClick={() =>
                  setActivePage(0) &&
                  setOpenedCustomer(null) &&
                  setOpenedOrder(99999)
                }
              >
                <div className="Dashboard_content_article_number">99999</div>
                <div className="Dashboard_content_article_date">01/01/2021</div>
                <div className="Dashboard_content_article_price">9999 €</div>
                <div className="Dashboard_content_article_status">En cours</div>
              </div>
            </div>
            <div className="Dashboard_content_client_info">
              <div className="Dashboard_content_client_info_title">
                Informations client
              </div>
              <div className="Dashboard_content_client_info_line">
                <div className="Dashboard_content_client_info_line_title">
                  Nom
                </div>
                <div className="Dashboard_content_client_info_line_content">
                  Dupont
                </div>
              </div>
              <div className="Dashboard_content_client_info_line">
                <div className="Dashboard_content_client_info_line_title">
                  Prénom
                </div>
                <div className="Dashboard_content_client_info_line_content">
                  Jean
                </div>
              </div>
              <div className="Dashboard_content_client_info_line">
                <div className="Dashboard_content_client_info_line_title">
                  Email
                </div>
                <div className="Dashboard_content_client_info_line_content">
                  jeandupont@gmail.com
                </div>
              </div>
              <div className="Dashboard_content_client_info_line">
                <div className="Dashboard_content_client_info_line_title">
                  Téléphone
                </div>
                <div className="Dashboard_content_client_info_line_content">
                  0612345678
                </div>
              </div>
              <div className="Dashboard_content_client_info_line">
                <div className="Dashboard_content_client_info_line_title">
                  Adresse
                </div>
                <div className="Dashboard_content_client_info_line_content">
                  1 rue de la Paix
                </div>
              </div>
              <div className="Dashboard_content_client_info_line">
                <div className="Dashboard_content_client_info_line_title">
                  Code postal
                </div>
                <div className="Dashboard_content_client_info_line_content">
                  75001
                </div>
              </div>
              <div className="Dashboard_content_client_info_line">
                <div className="Dashboard_content_client_info_line_title">
                  Ville
                </div>
                <div className="Dashboard_content_client_info_line_content">
                  Paris
                </div>
              </div>
              <div className="Dashboard_content_client_info_line">
                <div className="Dashboard_content_client_info_line_title">
                  Magasin préféré
                </div>
                <div className="Dashboard_content_client_info_line_content">
                  Porto-Vecchio
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {openedOrder === null && openedCustomer === null ? (
        <div className="Dashboard_menu">
          <div
            className={
              activePage === 0
                ? "Dashboard_menu_section_active"
                : "Dashboard_menu_section"
            }
            onClick={() => setActivePage(0)}
          >
            <Clipboard size={24} />
          </div>
          <div
            className={
              activePage === 1
                ? "Dashboard_menu_section_active"
                : "Dashboard_menu_section"
            }
            onClick={() => setActivePage(1)}
          >
            <Person size={24} />
          </div>
          <div
            className={
              activePage === 2
                ? "Dashboard_menu_section_active"
                : "Dashboard_menu_section"
            }
            onClick={() => setActivePage(2)}
          >
            <Shop size={24} />
          </div>
        </div>
      ) : (
        <div
          className="Dashboard_menu_back_button"
          onClick={() => setOpenedOrder(null) || setOpenedCustomer(null)}
        >
          <ArrowLeftSquare className="ArrowLeftSquare" size={24} color="#FFF" />{" "}
          Retour
        </div>
      )}
    </div>
  );
};
export default Dashboard;
