import React from "react";
import "./OnePage.css";
import Menu from "../Components/Menu";
import { Clock, Phone, PinMap } from "react-bootstrap-icons";

const OnePage = () => {
  const [menu, setMenu] = React.useState([
    {
      id: 1,
      category: "SUSHIS",
      japaneseCategory: "寿司",
      background: "sushis.png",
      status: false,
      subCategories: [
        {
          title: "Nigiri (x2)",
          japaneseTitle: "握り",
          description:
            "Les makis sont des rouleaux de riz vinaigré entourés d'une feuille d'algue nori et garnis de poisson cru, de légumes ou d'œufs de poisson.",
          items: [
            {
              ref: "N1",
              title: "Avocat",
              price: "3,90€",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      category: "RAMENS",
      japaneseCategory: "ラーメン",
      status: false,
      background: "ramens.png",
      subCategories: [
        {
          title: "Ramen",
          japaneseTitle: "ラーメン",
          description:
            "Les ramens sont des nouilles chinoises servies dans un bouillon à base de viande ou de poisson.",
          items: [
            {
              ref: "R1",
              title: "Poulet",
              price: "8,90€",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      category: "DESSERTS",
      japaneseCategory: "デザート",
      status: false,
      background: "mochi.png",
      subCategories: [
        {
          title: "Mochi",
          japaneseTitle: "餅",
          description: "Gâteau de riz gluant fourré à déguster glacé.",
          items: [
            {
              ref: "D1",
              title: "Fraise",
              price: "3,50€",
            },
          ],
        },
      ],
    },
    {
      id: 4,
      category: "BOISSONS",
      japaneseCategory: "飲み物",
      status: false,
      background: "drinks.png",
      subCategories: [
        {
          title: "Soda",
          japaneseTitle: "ソーダ",
          description: "Boisson gazeuse.",
          items: [
            {
              ref: "B1",
              title: "Coca-Cola",
              price: "2,50€",
            },
          ],
        },
      ],
    },
  ]);
  const switchCategory = (id) => {
    const newMenu = menu.map((item) => {
      if (item.id === id) {
        item.status = !item.status;
      }
      return item;
    });
    setMenu(newMenu);
  };

  return (
    <div className="OnePage">
      <Menu />
      <div className="OnePage_video_screen">
        <div className="OnePage_video_background">
          <div className="OnePage_Title">
            <div className="OnePage_Title_left">いなり寿司</div>
            <div className="OnePage_Title_right">
              <p className="OnePage_japanese_title">稲荷鮨</p>
              <h1>Sush'Inari</h1>
            </div>
          </div>
          <div className="OnePage_Title_bottom">
            <p>SUSHI SHOP - ASIAN FOOD</p>
          </div>
          <div className="OnePage_video_action_button">COMMANDER | 注文</div>
        </div>
      </div>
      <div className="OnePage_menu">
        {menu.map((item) => (
          <div
            key={item.id}
            className="OnePage_menu_category"
            onClick={() => switchCategory(item.id)}
            style={{
              backgroundImage: `url('../../images/${item.background}')`,
            }}
          >
            <div
              className="OnePage_menu_category_title"
              style={
                item.status ? { height: "fit-content" } : { height: "20vh" }
              }
            >
              {item.category}
              <span className="OnePage_menu_category_title_japanese">
                {item.japaneseCategory}
              </span>
            </div>
            <div
              className={
                item.status
                  ? "OnePage_menu_category_section"
                  : "OnePage_menu_category_section_hidden"
              }
            >
              {item.subCategories.map((subCategory) => (
                <div className="OnePage_menu_sub_category">
                  <div className="OnePage_menu_sub_category_title">
                    {subCategory.title}
                    <span className="OnePage_menu_sub_category_title_japanese">
                      {subCategory.japaneseTitle}
                    </span>
                  </div>
                  <div className="OnePage_menu_sub_category_description">
                    {subCategory.description}
                  </div>
                  {subCategory.items.map((item) => (
                    <div className="OnePage_menu_category_section_item">
                      <div className="OnePage_menu_category_section_item_ref">
                        {item.ref}
                      </div>
                      <div className="OnePage_menu_category_section_item_title">
                        {item.title}
                      </div>
                      <div className="OnePage_menu_category_section_item_price">
                        {item.price}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="OnePage_wheretofindus">
        <div className="OnePage_wheretofindus_background">
          <div className="OnePage_wheretofindus_title">
            Où nous trouver ?<br />
            <span className="OnePage_wheretofindus_title_japanese">
              ここはどこ
            </span>
          </div>
        </div>
        <div className="OnePage_wheretofindus_map"></div>
        <div className="OnePage_wheretofindus_address">
          <p className="OnePage_wheretofindus_address_title">
            <PinMap size={20} color="#c60000" />
            Adresse
          </p>
          <p className="OnePage_wheretofindus_address_content">
            Centre commerciale Espace covasina, 20240 Ventiseri
          </p>
          <p className="OnePage_wheretofindus_address_title">
            <Phone size={20} color="#c60000" />
            Téléphone
          </p>
          <p className="OnePage_wheretofindus_address_content">
            04 95 60 03 47
          </p>
          <p className="OnePage_wheretofindus_address_title">
            <Clock size={20} color="#c60000" />
            Horaires
          </p>
          <p className="OnePage_wheretofindus_address_content">
            Lundi : 17:00 - 21:30
          </p>
          <p className="OnePage_wheretofindus_address_content">
            Mardi : 17:00 - 21:30
          </p>
          <p className="OnePage_wheretofindus_address_content">
            Mercredi : 17:00 - 21:30
          </p>
          <p className="OnePage_wheretofindus_address_content">
            Jeudi : 17:00 - 21:30
          </p>
          <p className="OnePage_wheretofindus_address_content">
            Vendredi : 17:00 - 21:30
          </p>
          <p className="OnePage_wheretofindus_address_content">
            Samedi : 17:00 - 21:30
          </p>
          <p className="OnePage_wheretofindus_address_content">
            Dimanche : 17:00 - 21:30
          </p>
        </div>
      </div>
    </div>
  );
};
export default OnePage;
