import { useCallback, useEffect, useState } from "react";
import Menu from "../Components/Menu";
import "./ClickandCollect.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../Stripe/CheckoutForm";
import axios from "axios";
import { DNA } from "react-loader-spinner";

const ClickandCollect = () => {
  const stripePromise = loadStripe(
    "pk_test_51QPgRNKCJPd2Gpe5AfDljv6uqlHr430RNMz8vn4J0qOQfpefgPmxK3jp2ym6cZy67cJ7pm7BsOmuLiCCNn3Xx2DE00rsqAOGp1"
  );
  const [shopChoosen, setShopChoosen] = useState(null);
  const [cart, setCart] = useState([
    {
      box: 0,
      quantity: 0,
      name: "Plateau Sushi",
      price: 20,
      image: "sushi.jpg",
      content: [
        "6 sushis",
        "6 makis",
        "6 california rolls",
        "6 spring rolls",
        "Salade Wakamé",
        "Sauce soja",
        "Gingembre",
        "Wasabi",
      ],
    },
  ]);
  const [total, setTotal] = useState(0);
  const [totalService, setTotalService] = useState(0);
  const [custommer, setCustommer] = useState({
    command_id: 0,
    name: "",
    surname: "",
    email: "",
    phone: "",
    step: 0,
  });
  const [stripe, setStripe] = useState({
    card: "",
    exp: "",
    cvc: "",
    name: "",
  });
  const [formAlert, setFormAlert] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const addToCart = (box) => {
    setCart([...cart, cart[box].quantity++]);
  };
  const articleMore = (box) => {
    let newCart = [...cart];
    newCart[box].quantity++;
    setCart(newCart);
  };
  const articleLess = (box) => {
    let newCart = [...cart];
    newCart[box].quantity--;
    setCart(newCart);
  };
  const verifyCustommer = async () => {
    if (custommer.name.match(/^[a-zA-Z]+$/) === null) {
      setFormAlert("Nom invalide.");
    } else if (custommer.surname.match(/^[a-zA-Z]+$/) === null) {
      setFormAlert("Prénom invalide.");
    } else if (
      custommer.email.match(/^[a-zA-Z\.\-]+@[a-zA-Z\.\-]+$/) === null
    ) {
      setFormAlert("Email invalide.");
    } else if (custommer.phone.match(/^[0-9]{10}$/) === null) {
      setFormAlert("Téléphone invalide.");
    } else {
      setCustommer({ ...custommer, step: 2 });
    }
  };
  const clientSecretAsk = useCallback(async () => {
    const response = await axios.post(
      "https://sushinari.fr/api/stripe_secret",
      {
        amount: (total + totalService) * 100,
        taxe: Math.round((total + totalService) * 0.021 * 100) / 100,
        serviceCharge: totalService,
        custommer: custommer,
      }
    );
    if (response.data.success) {
      console.log("CLIENT SECRET", response.data.client_secret);
      setClientSecret(response.data.client_secret);
    } else {
      setFormAlert("Erreur lors de la demande de paiement.");
    }
  }, [total, totalService, custommer]);

  useEffect(() => {
    setTotal(
      cart.reduce(
        (acc, item) => acc + (item.price || 0) * (item.quantity || 0),
        0
      )
    );
    const totalItem = cart.reduce((acc, item) => acc + (item.quantity || 0), 0);
    if (totalItem > 0) {
      setTotalService(2);
    } else {
      setTotalService(0);
    }
  }, [cart]);
  useEffect(() => {
    if (custommer.step === 2 && clientSecret === "") {
      clientSecretAsk();
    } else if (custommer.step === 0) {
      setFormAlert("");
    }
  }, [custommer.step, clientSecret, clientSecretAsk]);
  useEffect(() => {
    setClientSecret("");
  }, [total]);

  return (
    <div className="ClickandCollect">
      <Menu />
      <div className="ClickandCollect_screen">
        <div className="ClickandCollect_background">
          <div className="ClickandCollect_Title">
            <div className="ClickandCollect_Title_Fr">
              <h1>Click & Collect</h1>
            </div>
            <div className="ClickandCollect_Title_Jp">
              <p>クリック ＆ コレクト</p>
            </div>
          </div>
          <div className="ClickandCollect_Explication">
            <p>Commandez en ligne et récupérez votre commande en magasin.</p>
          </div>
        </div>
      </div>
      {shopChoosen === null && (
        <>
          <div className="ClickandCollect_Shops_List_title">
            <h2> Choisissez votre magasin </h2>
          </div>
          <div className="ClickandCollect_Shops_List">
            <div
              className="ClickandCollect_Shop_case"
              onClick={() => setShopChoosen(1)}
            >
              <div className="ClickandCollect_Shop_case_title">Utile Travo</div>
            </div>
            <div
              className="ClickandCollect_Shop_case"
              onClick={() => setShopChoosen(2)}
            >
              <div className="ClickandCollect_Shop_case_title">
                Utile Abbazzia
              </div>
            </div>
          </div>
        </>
      )}
      {shopChoosen !== null && custommer.step === 0 && (
        <>
          <div className="ClickandCollect_Shops_List_back_button_zone">
            <div
              className="ClickandCollect_Shops_List_back_button"
              onClick={() => setShopChoosen(null)}
            >
              Retour
            </div>
          </div>
          <div className="ClickandCollect_Shops_List_title">
            <h2> Choisissez votre plateau </h2>
          </div>
          <div className="ClickandCollect_Box_List">
            <div className="ClickandCollect_Box_case">
              <div className="ClickandCollect_Box_case_title">
                Plateau Sushi
              </div>
              <div className="ClickandCollect_Box_case_price">20€</div>
              <div className="ClickandCollect_Box_case_image"></div>
              <div className="ClickandCollect_Box_case_description">
                <p>
                  Contient : <br />
                  <br />
                  - 6 sushis <br />
                  - 6 makis <br />
                  - 6 california rolls <br />
                  - 6 spring rolls <br />
                  - Salade Wakamé <br />
                  - Sauce soja <br />
                  - Gingembre <br />
                  - Wasabi <br />
                </p>
              </div>
              {cart[0].quantity === 0 ? (
                <div
                  className="ClickandCollect_Box_case_button"
                  onClick={() => addToCart(0)}
                >
                  Ajouter au panier
                </div>
              ) : (
                <div className="ClickandCollect_Box_case_number_of_box">
                  <div
                    className="ClickandCollect_Box_case_number_of_box_minus"
                    onClick={() => articleLess(0)}
                  >
                    -
                  </div>
                  <div className="ClickandCollect_Box_case_number_of_box_quantity">
                    {cart[0].quantity}
                  </div>
                  <div
                    className="ClickandCollect_Box_case_number_of_box_plus"
                    onClick={() => articleMore(0)}
                  >
                    +
                  </div>
                </div>
              )}
            </div>
          </div>
          {total > 0 && (
            <div
              className="ClickandCollect_Validation"
              onClick={() => setCustommer({ ...custommer, step: 1 })}
            >
              Passer la commande - {total + totalService} €
            </div>
          )}
        </>
      )}
      {custommer.step === 1 && (
        <div className="ClickandCollect_Custommer_info_zone">
          <div className="ClickandCollect_Custommer_info_validation_back_zone">
            <div
              className="ClickandCollect_Custommer_info_validation_back"
              onClick={() => setCustommer({ ...custommer, step: 0 })}
            >
              Retour
            </div>
          </div>
          <div className="ClickandCollect_Custommer_info_title">
            <h2>Informations client</h2>
          </div>
          <div className="ClickandCollect_Custommer_info_form">
            <input
              type="text"
              placeholder="Nom"
              onChange={(e) =>
                setCustommer({ ...custommer, name: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Prénom"
              onChange={(e) =>
                setCustommer({ ...custommer, surname: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Email"
              onChange={(e) =>
                setCustommer({ ...custommer, email: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Téléphone"
              onChange={(e) =>
                setCustommer({ ...custommer, phone: e.target.value })
              }
            />
          </div>
          <div
            className="ClickandCollect_Custommer_info_validation"
            onClick={verifyCustommer}
          >
            Passer au paiement
          </div>
        </div>
      )}
      {custommer.step === 2 && (
        <div className="ClickandCollect_Payment_zone">
          <div className="ClickandCollect_Payment_title">
            <h2>Paiement</h2>
          </div>
          <div className="ClickandCollect_Payment_back_button_zone">
            <div
              className="ClickandCollect_Payment_back_button"
              onClick={() => setCustommer({ ...custommer, step: 1 })}
            >
              Retour
            </div>
          </div>

          <div className="ClickandCollect_Payment_total">
            Total : {total + totalService} €
          </div>
          <div className="ClickandCollect_Payment_form_taxe">
            Dont frais de service : {totalService} €
          </div>
          <div className="ClickandCollect_Payment_form_taxe">
            Dont TVA : {Math.round((total + totalService) * 0.021 * 100) / 100}{" "}
            €
          </div>

          <div className="ClickandCollect_Payment_Stripe_Zone">
            {clientSecret !== "" ? (
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: clientSecret }}
              >
                <CheckoutForm
                  custommer={custommer}
                  totalTtc={
                    total +
                    totalService +
                    Math.round((total + totalService) * 0.021 * 100) / 100
                  }
                  total={total + totalService}
                  totalService={totalService}
                  taxe={Math.round((total + totalService) * 0.021 * 100) / 100}
                  clientSecret={clientSecret}
                  cart={cart}
                  setCart={setCart}
                  setCustommer={setCustommer}
                />
              </Elements>
            ) : (
              <DNA
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            )}
          </div>
          <div className="ClickandCollect_Payment_form_alert">{formAlert}</div>
        </div>
      )}
      {custommer.step === 3 && (
        <div className="ClickandCollect_Thanks">
          <h2>Merci pour votre commande !</h2>
          <p className="ClickandCollect_Thanks_sentence">
            Vous allez recevoir un mail de confirmation.
          </p>
          <div className="ClickandCollect_Thanks_commande">
            <h3>Commandes :</h3>
            <div className="ClickandCollect_Thanks_commande_id">
              n° {custommer.command_id}
            </div>
            <div className="ClickandCollect_Thanks_commande_detail">
              {cart.map((item, index) => {
                if (item.quantity > 0) {
                  return (
                    <div
                      key={index}
                      className="ClickandCollect_Thanks_commande_item"
                    >
                      {item.quantity} x {item.name} -{" "}
                      {item.price * item.quantity} €
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <div className="ClickandCollect_Thanks_commande_total">
              Total : {total + totalService} €
            </div>
          </div>
          <div
            className="ClickandCollect_Thanks_commande_back_button"
            onClick={() => {
              // lien vers la page racine
              window.location.href = "/";
            }}
          >
            Terminer
          </div>
        </div>
      )}
    </div>
  );
};

export default ClickandCollect;
